<!-- 维修管理-维修工单 -->
<template>
  <div class="page_container height_100">
    <div class="bgc_FFFFFF height_100">
      <div class="box_head1">
        <span>维修工单</span>
        <div class="flex align_center fn_size14 mg_left_50">
          <div>设备编号：</div>
          <el-input
            v-model="deviceID"
            placeholder="请输入设备编号"
            class="mg_left_8 width_150"
          ></el-input>
          <div class="mg_left_20">工单状态：</div>
          <el-select
            v-model="workOrderStatusValue"
            placeholder="请选择"
            class="mg_left_8 width_150"
            clearable
          >
            <el-option
              v-for="item in workOrderStatus"
              :key="item.value"
              :label="item.description"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- <div class="mg_left_20">评分等级：</div>
                    <el-select v-model="gradingArrValue" placeholder="请选择" class="mg_left_8 width_150" clearable>
                        <el-option v-for="(item,index) in gradingArr" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
          <div class="mg_left_20">维修类型：</div>
          <el-select
            v-model="repairCategoryValue"
            placeholder="请选择"
            class="mg_left_8 width_150"
            clearable
          >
            <el-option
              v-for="(item, index) in repairCategory"
              :key="index"
              :label="item.description"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="mg_left_20">维修时间：</div>
          <el-date-picker
            value-format="timestamp"
            v-model="repairTimeValue"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
          <el-button
            type="primary"
            class="mg_left_10"
            size="medium"
            @click="query"
            >查询
          </el-button>
        </div>
      </div>
      <div class="flex justify_between pdt_20 fn_size16 pdl30 pdr30">
        <div class="flex">
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            class="width_120"
            style="background: #03cabe; border: none"
            @click="gotoFastOrder"
            >添加工单</el-button
          >
          <el-button
            type="primary"
            class="mg_left_10"
            @click="exportRepairRecord"
            style="background: #03cabe; border: none"
            >导出</el-button
          >
          <!-- <el-button type="primary" class="width_120" style=" background: #FEA313;border: none;">派工
                    </el-button> -->
          <!-- <el-button type="primary" class="width_120" style=" background: #657798;border: none;"
                        @click="approvalOperation">审批
                    </el-button>
                    <el-button type="primary" class="width_120" style=" background: #20B6DA;border: none;"
                        @click="dispatchOperation">派单
                    </el-button>
                    <el-button type="primary" class="width_120" style=" background: #E4649D;border: none;"
                        @click="receivingOrders">接单
                    </el-button>
                    <el-button type="primary" class="width_120" style=" background: #5781EA;border: none;"
                        @click="implementOperation">执行
                    </el-button>
                    <el-button type="primary" class="width_120" style=" background: #FC8A69;border: none;"
                        @click="temporarilyPart">暂离
                    </el-button>
                    <el-button type="primary" class="width_120" style=" background: #5781EA;border: none;"
                        @click="temporarilyPartEnd">暂离结束
                    </el-button>
                    <el-button type="primary" class="width_120" style=" background: #E3AA3C;border: none;"
                        @click="orderStatement">结单
                    </el-button>
                    <el-button type="primary" class="width_120" style=" background: #5781EA;border: none;"
                        @click="verificationOperation">验证
                    </el-button>
                    <el-button type="primary" class="width_120" style=" background: #F25565;border: none;"
                        @click="scoreOperation">评分
                    </el-button> -->
          <!-- <el-button type="primary" class="width_120" style=" background: #657798;border: none;">验证
                    </el-button>
                    <el-button type="primary" class="width_120" style=" background: #5781EA;border: none;">评分
                    </el-button> -->
          <!-- <el-button type="primary" icon="el-icon-delete" class="width_120 mg_left_10"
                        style=" background: #F75753;border: none;">删除</el-button> -->
          <!-- <el-button type="primary" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #03CABE;border: none;">导出</el-button> -->
        </div>
      </div>
      <div class="pdl30 pdr30" style="height: 78%">
        <el-table
          ref="multipleTable"
          :data="repairOrderList"
          tooltip-effect="dark"
          style="width: 100%"
          class="mg_top_20"
          height="100%"
          @selection-change="handleSelectionChange"
          @row-dblclick="dblclickGotoFaultDetails"
          border=""
          id="repairPlanTable"
        >
          <el-table-column type="index" label="序号" width="55">
          </el-table-column>
          <el-table-column
            prop="orderID"
            label="工单编号"
            width=""
            show-overflow-tooltip
          >
            <!-- 判断
                        <template slot-scope="scope">
                            <el-tag type="info" v-if="scope.row.alarmStatus == 0">未处理</el-tag>
                            <el-tag v-if="scope.row.alarmStatus == 1">已处理</el-tag>
                        </template> -->
          </el-table-column>
          <!-- <el-table-column prop="projectID" label="公司编号" width="">
                    </el-table-column> -->
          <el-table-column prop="deviceID" label="设备编号" width="">
          </el-table-column>
          <el-table-column prop="deviceName" label="设备名称">
          </el-table-column>
          <!-- <el-table-column prop="deviceType" label="设备类型">
                    </el-table-column> -->
          <el-table-column prop="deviceModel" label="设备型号" width="">
          </el-table-column>
          <!-- <el-table-column prop="authSort" label="审批顺序">
                    </el-table-column> -->

          <el-table-column label="是否需要审批">
            <template slot-scope="scope">
              <span>{{ scope.row.isAuth ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="deviceDepartment" label="设备所在部门" width="">
                    </el-table-column> -->
          <!-- <el-table-column prop="deviceLocation" label="设备所在地点">
                    </el-table-column> -->
          <!-- <el-table-column prop="failureTime" label="故障时间" :formatter="formatDate">
                    </el-table-column> -->
          <!-- <el-table-column prop="oprPerson" label="记录人">
                    </el-table-column>
                    <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate" show-overflow-tooltip>
                    </el-table-column> -->
          <!-- <el-table-column prop="failureCode" label="故障代码">
                    </el-table-column> -->
          <el-table-column prop="failureDevPart" label="故障部位">
          </el-table-column>
          <!-- <el-table-column prop="failureLevel" label="故障等级">
                    </el-table-column> -->
          <el-table-column prop="failureType" label="故障类型">
          </el-table-column>
          <!-- <el-table-column prop="failureDesc" label="故障描述">
                    </el-table-column>
                    <el-table-column prop="failureReason" label="故障原因">
                    </el-table-column> -->
          <!-- <el-table-column prop="execGroup" label="维修组">
                    </el-table-column>
                    <el-table-column prop="execPerson" label="维修人">
                    </el-table-column> -->
          <el-table-column prop="orderStatus_name" label="维修工单状态">
          </el-table-column>
          <!-- <el-table-column label="维修工单状态">
                        <template slot-scope="scope">
                            <span>{{scope.row.orderStatus}}</span>
                        </template>
                    </el-table-column> -->
          <!-- <el-table-column prop="execLevel" label="维修等级" width="">
                    </el-table-column> -->
          <el-table-column prop="execType" label="维修类型"> </el-table-column>
          <!-- <el-table-column prop="execEstimateLen" label="维修预估花费时间（分钟）">
                    </el-table-column> -->
          <el-table-column prop="urgenLevel_name" label="紧急程度">
          </el-table-column>
          <!-- <el-table-column label="是否停机">
                        <template slot-scope="scope">
                            <span>{{scope.row.machineStop}}</span>
                        </template>
                    </el-table-column> -->
          <!-- <el-table-column prop="stopLen" label="停机时长（分钟）">
                    </el-table-column> -->
          <!-- <el-table-column prop="dispatchTime" label="派单时间" :formatter="formatDate">
                    </el-table-column> -->
          <!-- <el-table-column prop="assignTakeTime" label="指定接单时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column label="是否逾期接单">
                        <template slot-scope="scope">
                            <span>{{scope.row.isTakeOverdue}}</span>
                        </template>
                    </el-table-column> -->
          <!-- <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="startTime" label="维修开始时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="endTime" label="维修结束时间" :formatter="formatDate">
                    </el-table-column> -->

          <!-- <el-table-column label="是否逾期结单结束">
                        <template slot-scope="scope">
                            <span>{{scope.row.isEndOverdue}}</span>
                        </template>
                    </el-table-column> -->
          <!-- <el-table-column prop="assignEndTime" label="指定维修结束时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="pauseTimeList" label="暂离时间列表" width="" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="verifyTime" label="验证时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="verifyRemarks" label="验证备注">
                    </el-table-column>
                    <el-table-column prop="execRejectStartTime" label="验证不通过维修开始时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="execRejectEndTime" label="验证不通过维修结束时间" :formatter="formatDate">
                    </el-table-column> -->
          <!-- <el-table-column prop="execLenM" label="维修时长（分钟）">
                    </el-table-column> -->
          <el-table-column prop="execFee" label="维修费用（元）" width="">
          </el-table-column>
          <!-- <el-table-column prop="spotCondition" label="现场状况">
                    </el-table-column>
                    <el-table-column prop="execDesc" label="维修描述">
                    </el-table-column>
                    <el-table-column prop="execGrade" label="工单评分等级">
                    </el-table-column>
                    <el-table-column label="是否为经验性记录">
                        <template slot-scope="scope">
                            <span>{{scope.row.isEmpirical}}</span>
                        </template>
                    </el-table-column> -->

          <!-- <el-table-column prop="remarks" label="备注">
                    </el-table-column> -->
          <!-- <el-table-column label="是否委外">
                        <template slot-scope="scope">
                            <span>{{scope.row.isOutEntrust}}</span>
                        </template>
                    </el-table-column> -->
          <!-- <el-table-column label="是否可以进行审批" width="">
                        <template slot-scope="scope">
                            <span>{{scope.row.doAuth}}</span>
                        </template>
                    </el-table-column> -->
          <!-- <el-button type="primary" class="width_120" style=" background: #657798;border: none;"
                            @click="approvalOperation">审批
                        </el-button>
                        <el-button type="primary" class="width_120" style=" background: #20B6DA;border: none;"
                            @click="dispatchOperation">派单
                        </el-button>
                        <el-button type="primary" class="width_120" style=" background: #E4649D;border: none;"
                            @click="receivingOrders">接单
                        </el-button>
                        <el-button type="primary" class="width_120" style=" background: #5781EA;border: none;"
                            @click="implementOperation">执行
                        </el-button>
                        <el-button type="primary" class="width_120" style=" background: #FC8A69;border: none;"
                            @click="temporarilyPart">暂离
                        </el-button>
                        <el-button type="primary" class="width_120" style=" background: #5781EA;border: none;"
                            @click="temporarilyPartEnd">暂离结束
                        </el-button>
                        <el-button type="primary" class="width_120" style=" background: #E3AA3C;border: none;"
                            @click="orderStatement">结单
                        </el-button>
                        <el-button type="primary" class="width_120" style=" background: #5781EA;border: none;"
                            @click="verificationOperation">验证
                        </el-button>
                        <el-button type="primary" class="width_120" style=" background: #F25565;border: none;"
                            @click="scoreOperation">评分
                        </el-button> -->
          <el-table-column prop="authRoleName" label="审批角色">
          </el-table-column>
          <el-table-column prop="isOutEntrust" label="是否委外">
            <template slot-scope="scope">
              <span>{{ scope.row.isOutEntrust ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="oprTime" label="创建时间" width="160px" :formatter="formatDate"> </el-table-column>
          <el-table-column prop="oprPerson" label="创建人"> </el-table-column>
          <el-table-column label="详情" width="150">
            <template slot-scope="scope">
              <el-button
                type="text"
                class="color_009BFD pd_0"
                @click="gotoDetails(scope.row.ID)"
              >
                <span style="border-bottom: 1px solid #009bfd">详情</span>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="170">
            <template slot-scope="scope">
              <el-button
                type="text"
                class="color_009BFD pd_0"
                @click="gotoEditOrder(scope.row.ID)"
                v-if="
                  scope.row.orderStatus != 8 &&
                  scope.row.orderStatus != 9 &&
                  scope.row.orderStatus != 11
                "
              >
                <span style="border-bottom: 1px solid #009bfd">修改</span>
              </el-button>
                <el-button
                  type="text"
                  class="color_009BFD pd_0"
                  @click="delOrder(scope.row.ID)"
                  v-if="scope.row.orderStatus < 4 && scope.row.doDelete == true"
                >
                  <span style="border-bottom: 1px solid #009bfd">删除</span>
                </el-button>
              
              <el-button
                type="text"
                class="color_009BFD pd_0"
                @click="approvalOperation(scope.row.ID)"
                v-if="scope.row.doAuth === true"
              >
                <span style="border-bottom: 1px solid #009bfd">审批</span>
                <!-- <span v-else style="border-bottom: 1px solid #009BFD;"></span> -->
              </el-button>
                <el-button
                  type="text"
                  class="color_009BFD pd_0"
                  v-if="scope.row.orderStatus == 1 && scope.row.doDispatch == true"
                  @click="dispatchOperation(scope.row.ID)"
                >
                  <span style="border-bottom: 1px solid #009bfd">派单</span>
                </el-button>
              <el-button
                type="texdispatchOperationt"
                class="color_009BFD pd_0"
                v-if="scope.row.orderStatus == 4"
                @click="receivingOrders(scope.row.ID)"
              >
                <span style="border-bottom: 1px solid #009bfd">接单</span>
              </el-button>
              <el-button
                type="text"
                class="color_009BFD pd_0"
                v-if="scope.row.orderStatus == 5"
                @click="implementOperation(scope.row.ID)"
              >
                <span style="border-bottom: 1px solid #009bfd">执行</span>
              </el-button>
              <el-button
                type="text"
                class="color_009BFD pd_0"
                v-if="scope.row.orderStatus == 7 || scope.row.orderStatus == 10"
                @click="orderStatement(scope.row)"
              >
                <span style="border-bottom: 1px solid #009bfd">结单</span>
              </el-button>
                <el-button
                  type="text"
                  class="color_009BFD pd_0"
                  v-if="scope.row.orderStatus == 8 && scope.row.doVerify == true"
                  @click="verificationOperation(scope.row.ID)"
                >
                  <span style="border-bottom: 1px solid #009bfd">验证</span>
                </el-button>
                <el-button
                  type="text"
                  class="color_009BFD pd_0"
                  v-if="scope.row.orderStatus == 9 && scope.row.doGrade == true"
                  @click="scoreOperation(scope.row.ID)"
                >
                  <span style="border-bottom: 1px solid #009bfd">评分</span>
                </el-button>
              <el-button
                type="text"
                class="color_009BFD pd_0"
                v-if="scope.row.orderStatus == 7"
                @click="temporarilyPart(scope.row.ID)"
              >
                <span style="border-bottom: 1px solid #009bfd">暂离</span>
              </el-button>
              <el-button
                type="text"
                class="color_009BFD pd_0"
                v-if="scope.row.orderStatus == 6"
                @click="temporarilyPartEnd(scope.row.ID)"
              >
                <span style="border-bottom: 1px solid #009bfd">暂离结束</span>
              </el-button>
            </template>
          </el-table-column>
          <!-- <el-table-column label="执行">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;" @click="gotoDetails(scope.row.ID)">详细</span>
                            </el-button>
                        </template>
                    </el-table-column> -->
        </el-table>
        <el-pagination
          layout="prev, pager, next"
          :total="pageQuantity * pageSize"
          :page-size="pageSize"
          :current-page.sync="currentPage"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      <el-dialog :visible.sync="dialogVisible" width="30%">
        <div
          slot="title"
          class="fn_size18 color_000000 flex justify_center font_bold"
        >
          审批
        </div>
        <div class="height_110 flex justify_center align_center">
          <el-radio-group v-model="approvalListValue" class="">
            <el-radio
              v-for="item in approvalList"
              :key="item.value"
              :label="item.value"
            >
              {{ item.description }}
            </el-radio>
          </el-radio-group>
        </div>
        <div class="flex justify_center pdb_20">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confrimApproval">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="派单" :visible.sync="dialogVisibleDispatch" width="30%">
        <!-- <div class="flex flex_column "> -->
        <div class="flex justify_center mg_top_20">
          <el-form :model="formDispatch" :rules="rulesFrom">
            <el-form-item
              label="是否委外："
              :label-width="formLabelWidth"
              class=""
            >
              <el-checkbox
                v-model="formDispatch.isOutEntrust"
                class="text_align_left"
                @change="changeIsOutEntrust"
              >
              </el-checkbox>
            </el-form-item>
            <el-form-item
              label="维修组："
              :label-width="formLabelWidth"
              v-if="!this.formDispatch.isOutEntrust"
              prop="execGroup"
            >
              <el-select
                v-model="formDispatch.execGroup"
                placeholder="请选择"
                class=""
              >
                <el-option
                  v-for="item in maintenance"
                  :key="item.value"
                  :label="item.description"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="维修人："
              :label-width="formLabelWidth"
              v-if="!this.formDispatch.isOutEntrust"
            >
              <el-select
                v-model="formDispatch.execPerson"
                placeholder="请选择"
                class=""
                multiple
                collapse-tags
              >
                <el-option
                  v-for="(item, index) in maintenancePeople"
                  :key="index"
                  :label="item.userName"
                  :value="item.userName"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="维修预估花费时间(分钟)："
              :label-width="formLabelWidth"
              v-if="!this.formDispatch.isOutEntrust"
              prop="execEstimateLen"
            >
              <el-input
                v-model="formDispatch.execEstimateLen"
                type="number"
                class="width_220"
                oninput="if(value<0){value=0}"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="指定维修开始时间："
              :label-width="formLabelWidth"
              v-if="!this.formDispatch.isOutEntrust"
              prop="assignTakeTime"
            >
              <el-date-picker
                value-format="timestamp"
                v-model="formDispatch.assignTakeTime"
                type="datetime"
                placeholder="选择日期时间"
                class=""
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="指定维修结束时间："
              :label-width="formLabelWidth"
              v-if="!this.formDispatch.isOutEntrust"
              prop="assignEndTime"
            >
              <el-date-picker
                value-format="timestamp"
                v-model="formDispatch.assignEndTime"
                type="datetime"
                placeholder="选择日期时间"
                class=""
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div class="flex justify_center pdb_20">
          <el-button @click="dialogVisibleDispatch = false">取 消</el-button>
          <el-button type="primary" @click="confirmChoicesEdit"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <el-dialog :visible.sync="dialogVisibleImplement" width="30%">
        <div
          slot="title"
          class="fn_size18 color_000000 flex justify_center font_bold"
        >
          执行
        </div>
        <div class="height_110 flex justify_center align_center">
          <el-form :model="formImplement">
            <el-form-item label="执行类型：" :label-width="formLabelWidth">
              <el-select
                v-model="formImplement.orderExecType"
                placeholder="请选择"
                class=""
              >
                <el-option
                  v-for="item in executeType"
                  :key="item.value"
                  :label="item.description"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="flex justify_center pdb_20">
          <el-button @click="dialogVisibleImplement = false">取 消</el-button>
          <el-button type="primary" @click="confrimImplement">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog :visible.sync="dialogVisibleStatement" width="25%">
        <div
          slot="title"
          class="fn_size18 color_000000 flex justify_center font_bold"
        >
          结单
        </div>
        <!-- <div class="flex flex_column "> -->
        <div class="flex justify_center mg_top_20">
          <el-form
            :model="formStatement"
            :rules="rulesFrom"
            ref="formStatement"
          >
            <el-form-item
              label="结单类型："
              :label-width="formLabelWidth"
              prop="orderFinishType"
            >
              <el-select
                v-model="formStatement.orderFinishType"
                placeholder="请选择"
                class=""
              >
                <el-option
                  v-for="item in finishTypeQuery"
                  :key="item.value"
                  :label="item.description"
                  :value="item.value"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="是否停机："
              :label-width="formLabelWidth"
              class=""
            >
              <el-checkbox
                v-model="formStatement.machineStop"
                class="width_220 text_align_left"
              >
              </el-checkbox>
            </el-form-item>
            <el-form-item
              label="停机时长（分钟）："
              :label-width="formLabelWidth"
              v-if="formStatement.machineStop"
              prop="stopLen"
            >
              <el-input
                v-model="formStatement.stopLen"
                type="number"
                oninput="if(value<0){value=0}"
                autocomplete="off"
                class=""
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="工作描述："
              :label-width="formLabelWidth"
              prop="execDesc"
            >
              <el-input
                v-model="formStatement.execDesc"
                autocomplete="off"
                class=""
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="现场状况："
              :label-width="formLabelWidth"
              prop="spotCondition"
            >
              <el-input
                v-model="formStatement.spotCondition"
                autocomplete="off"
                class=""
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="维修费用(元)："
              :label-width="formLabelWidth"
            >
                          <!-- prop="execFee" -->
              <el-input
                v-model="formStatement.execFee"
                autocomplete="off"
                type="number"
                oninput="if(value<0){value=0}"
                class=""
              ></el-input>
            </el-form-item>
            <el-form-item label="备注：" :label-width="formLabelWidth">
              <el-input
                v-model="formStatement.remarks"
                autocomplete="off"
                class=""
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="flex justify_center pdb_20">
          <el-button @click="dialogVisibleStatement = false">取 消</el-button>
          <!-- <el-button type="primary" @click="confirmStatement('formStatement')">确 定</el-button> -->
          <el-button type="primary" @click="confirmStatement()"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <el-dialog :visible.sync="dialogVisibleVerification" width="30%">
        <div
          slot="title"
          class="fn_size18 color_000000 flex justify_center font_bold"
        >
          验证
        </div>
        <!-- <div class="flex flex_column "> -->
        <div class="flex justify_center mg_top_20">
          <el-form :model="formStatement">
            <el-form-item
              label="是否通过："
              :label-width="formLabelWidth"
              class=""
            >
              <el-checkbox
                v-model="formStatement.verifyResult"
                class="width_300 text_align_left"
              >
              </el-checkbox>
            </el-form-item>
            <el-form-item label="验证备注：" :label-width="formLabelWidth">
              <el-input
                v-model="formStatement.verifyRemarks"
                autocomplete="off"
                class=""
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="flex justify_center pdb_20">
          <el-button @click="closeConfirmVerification">取 消</el-button>
          <el-button type="primary" @click="confirmVerification"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <el-dialog :visible.sync="dialogVisibleScore" width="30%">
        <div
          slot="title"
          class="fn_size18 color_000000 flex justify_center font_bold"
        >
          评分
        </div>
        <!-- <div class="flex flex_column "> -->
        <div class="flex justify_center mg_top_20">
          <el-form :model="formStatement">
            <el-form-item
              label="工单评分等级："
              :label-width="formLabelWidth"
              class=""
            >
              <el-select
                v-model="formStatement.execGrade"
                placeholder="全部"
                class=""
              >
                <el-option
                  v-for="item in gradingArr"
                  :key="item.value"
                  :label="item.description"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="flex justify_center pdb_20">
          <el-button @click="dialogVisibleScore = false">取 消</el-button>
          <el-button type="primary" @click="confirmScore">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
//   import innerTopNav from '../../../components/innerTopNav'
import {
  repairOrderMultiAuthQueryLimit,
  repairOrderDelete,
  repairOrderStatusQuery,
  repairOrderExecGradeQuery,
  repairTypeQuery,
  repairOrderAuthorize,
  multiAuthorizeResultQuery,
  repairGroupQuery,
  repairExecPersonQuery,
  repairOrderDispatch,
  repairOrderTake,
  repairOrderExecute,
  repairOrderPause,
  repairOrderContinue,
  repairOrderRepairFinish,
  repairOrderVerify,
  repairOrderMarkEmpirical,
  repairOrderRepairGrade,
  repairOrderExecuteTypeQuery,
  repairOrderFinishTypeQuery,
} from "@/api/api_deviceManagement";
export default {
  // components: {
  //   innerTopNav
  // },
  props: {
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      tabIndex: 0,
      value: "",
      multipleSelection: [],
      repairOrderList: [],
      currentPage: 1,
      pageSize: 10,
      pageQuantity: 0,
      workOrderStatusValue: "",
      workOrderStatus: [],
      gradingArrValue: "",
      gradingArr: [],
      repairCategoryValue: "",
      repairCategory: [],
      repairTimeValue: null,
      userID: "",
      dialogVisible: false,
      approvalListValue: undefined,
      approvalList: [],
      dialogVisibleDispatch: false,
      formDispatch: {},
      formLabelWidth: "200px",
      maintenance: [],
      maintenancePeople: [],
      dialogVisibleImplement: false,
      formImplement: {},
      dialogVisibleStatement: false,
      formStatement: {},
      dialogVisibleVerification: false,
      dialogVisibleScore: false,
      executeType: [],
      finishTypeQuery: [],
      userID: "",
      approval: "",
      patch: "",
      typequery: "",
      verification: "",
      score: "",
      deviceID: "",
      rulesFrom: {
        orderFinishType: [
          {
            required: true,
            message: "请选择结单类型",
            trigger: "blur",
          },
        ],
        // machineStop:[{
        //     required: true,
        //     message: '请选择勾选是否停机',
        //     trigger: 'blur'
        // }],
        stopLen: [
          {
            required: true,
            message: "请选输入停机时长(分钟)",
            trigger: "blur",
          },
        ],
        execDesc: [
          {
            required: true,
            message: "请选输入工作描述",
            trigger: "blur",
          },
        ],
        spotCondition: [
          {
            required: true,
            message: "请选输入现场状况",
            trigger: "blur",
          },
        ],
        execFee: [
          {
            required: true,
            message: "请选输入维修费用(元)",
            trigger: "blur",
          },
        ],
        isOutEntrust: [
          {
            required: true,
            message: "请选勾选是否委外",
            trigger: "blur",
          },
        ],
        execGroup: [
          {
            required: true,
            message: "请选择维修组",
            trigger: "blur",
          },
        ],
        execEstimateLen: [
          {
            required: true,
            message: "请填写维修预估花费时间(分钟)",
            trigger: "blur",
          },
        ],
        assignTakeTime: [
          {
            required: true,
            message: "请选择维修开始时间",
            trigger: "blur",
          },
        ],
        assignEndTime: [
          {
            required: true,
            message: "请选择维修结束时间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    if (localStorage.getItem("userInfo") != null) {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.userID = userInfo.userID;
    }
    this.repairOrderMultiAuthQueryLimit();
    this.repairOrderStatusQuery();
    this.repairOrderExecGradeQuery();
    this.repairTypeQuery();
  },
  methods: {
    //正则表达式限制文本框只能输入正数，小数
    // BlurText(e) {
    // let boolean = new RegExp("/[^\d.]/g,''").test(e.target.value)
    // if(!boolean) {
    // this.$message.warn('请输入正整数')
    // e.target.value = ' '
    // }
    // },
    async rMIGradeSettingsQuery() {
      var parame = {
        // userID:'user_ls'
      };
      const selectRes = await rMIGradeSettingsQuery(parame);
    },
    async repairOrderStatusQuery() {
      var param = {
        userID: this.userID,
      }; //用户登录时
      const selectRes = await repairOrderStatusQuery(param);
      this.workOrderStatus = selectRes.data;
    },
    async repairOrderExecGradeQuery() {
      var param = {
        userID: this.userID,
      }; //用户登录时
      const selectRes = await repairOrderExecGradeQuery(param);
      this.gradingArr = selectRes.data;
    },
    async repairTypeQuery() {
      var param = {
        userID: this.userID,
      }; //用户登录时
      const selectRes = await repairTypeQuery(param);
      this.repairCategory = selectRes.data;
    },
    async repairOrderMultiAuthQueryLimit() {
      var param = {
        // userID:"user_zzc",
        userID: this.userID,
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
      }; //用户登录时
      if (this.deviceID) {
        param.deviceID = this.deviceID;
      }
      if (
        this.workOrderStatusValue.toString() != "" &&
        this.workOrderStatusValue != null
      ) {
        param.orderStatus = this.workOrderStatusValue;
      }
      if (this.gradingArrValue != "" && this.gradingArrValue != null) {
        param.execLevel = this.gradingArrValue;
      }
      if (this.repairCategoryValue != "" && this.repairCategoryValue != null) {
        param.execType = this.repairCategoryValue;
      }
      if (this.repairTimeValue != null) {
        param.startExecTime = this.repairTimeValue[0];
        param.endExecTime = this.repairTimeValue[1];
      }
      const selectRes = await repairOrderMultiAuthQueryLimit(param);
      this.repairOrderList = selectRes.array;
      this.pageQuantity = selectRes.pageQuantity;
    },
    query() {
      this.currentPage = 1;
      this.repairOrderMultiAuthQueryLimit();
    },
    handleCurrentChange(val) {
      // this.PageNumber = val;
      this.repairOrderMultiAuthQueryLimit(); //查询table数据的方法
    },
    delOrder(id) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.repairOrderDelete(id);
        })
        .catch(() => {
          // this.$message({
          //     type: 'info',
          //     message: '已取消删除'
          // });
        });

      // repairOrderDelete
    },
    async repairOrderDelete(id) {
      var param = {
        ID: id,
      };
      const selectRes = await repairOrderDelete(param);
      if (selectRes.data.result) {
        this.$message({
          message: selectRes.message,
          type: "success",
        });
        this.currentPage = this.delData.delData(
          this.repairOrderList.length,
          this.pageQuantity,
          this.currentPage
        );
        this.repairOrderMultiAuthQueryLimit();
      } else {
        this.$message.error(selectRes.message);
      }
    },
    gotoFastOrder() {
      this.$router.push({
        path: "/deviceManagement/maintenanceManagement/repairOrder/fastOrder",
        // query:{setid:111222}
        // params:{setid:111222}//地址栏中看不到参数的内容
      });
    },
    gotoEditOrder(id) {
      localStorage.setItem("changeOrderID", id);
      this.$router.push({
        path: "/deviceManagement/maintenanceManagement/repairOrder/editOrder",
      });
    },
    gotoDetails(id) {
      localStorage.setItem("deitailID", id);
      //  console.log("id",id);
      this.$router.push({
        path: "/deviceManagement/maintenanceManagement/repairOrder/maintenanceDetails",
        // query:{setid:111222}
        // params:{setid:111222}//地址栏中看不到参数的内容
      });
    },

    // 审批--开始
    async approvalOperation(id) {
      // if (this.multipleSelection.join(',') == '') {
      //     this.$message({
      //         message: '警告：请选择需要审批的数据！！！',
      //         type: 'warning'
      //     });
      //     return
      // }
      // if (this.multipleSelection.length > 1) {
      //     this.$message({
      //         message: '警告：请选择一条需要审批的数据！！！',
      //         type: 'warning'
      //     });
      //     return
      // }

      const selectRes = await multiAuthorizeResultQuery();
      // console.log(selectRes);
      this.approvalList = selectRes.array;
      // this.approvalListValue = selectRes.array[0].value;
      this.approval = id;
      this.dialogVisible = true;
    },
    //审批
    async confrimApproval() {
      if(this.approvalListValue == undefined){
        this.$message.error('请选择是否通过！');  
      }else{
        var param = {
          // userID: "user_aa",
          userID: this.userID,
          ID: this.approval,
          authResult: this.approvalListValue,
        };

        const selectRes = await repairOrderAuthorize(param);
        //console.log("-----",selectRes)
        if (selectRes.data.result) {
          this.$message({
            message: selectRes.message,
            type: "success",
          });
          //审批结束后刷新页面
          this.repairOrderMultiAuthQueryLimit();
          (this.approvalListValue = {}), (this.dialogVisible = false);
        } else {
          this.$message.error(selectRes.message);
        }
      }

    },

    //  派单--开始
    dispatchOperation(id) {
      this.formDispatch = {};
      this.formDispatch = {
        isOutEntrust: false,
      };
      this.dialogVisibleDispatch = true;
      this.repairGroupQuery();
      this.repairExecPersonQuery();
      this.patch = id;
      // console.log(id, this.patch);
    },
    async repairGroupQuery() {
      var param = {
        userID: this.userID,
      };
      const selectRes = await repairGroupQuery(param);
      this.maintenance = selectRes.data;
    },
    async repairExecPersonQuery() {
      var param = {
        userID: this.userID,
      };
      const selectRes = await repairExecPersonQuery(param);
      this.maintenancePeople = selectRes.array;
    },
    async confirmChoicesEdit() {
      //this.dialogVisibleDispatch = false;
      var param = {
        ID: this.formDispatch.ID,
        isOutEntrust: this.formDispatch.isOutEntrust,
        execGroup: this.formDispatch.execGroup,
        execEstimateLen: this.formDispatch.execEstimateLen,
        assignTakeTime: this.formDispatch.assignTakeTime,
        assignEndTime: this.formDispatch.assignEndTime,
        execPerson: this.formDispatch.execPerson,
      };
      if (this.formDispatch.isOutEntrust) {
        param.ID = this.patch;
        param.isOutEntrust = this.formDispatch.isOutEntrust;
      } else {
        param = this.formDispatch;
        if (
          !param.execGroup ||
          param.execGroup == "" ||
          !param.execEstimateLen ||
          param.execEstimateLen == "" ||
          !param.assignTakeTime ||
          param.assignTakeTime == "" ||
          !param.assignEndTime ||
          param.assignEndTime == ""
        ) {
          this.$message.error("请填写必填项！");
          return;
        }
        this.formDispatch.ID = this.patch;
        this.formDispatch.execPerson = this.formDispatch.execPerson.toString();
        param = this.formDispatch;
      }
      const selectRes = await repairOrderDispatch(param);
      if (selectRes.data.result) {
        this.$message({
          message: selectRes.message,
          type: "success",
        });
        this.dialogVisibleDispatch = false;
        this.repairOrderMultiAuthQueryLimit();
        this.formDispatch = {};
      } else {
        this.$message.error("接口错误！");
      }
    },

    // 接单--开始
    async receivingOrders(id) {
      // if (this.multipleSelection.join(',') == '') {
      //     this.$message({
      //         message: '警告：请选择需要接单的数据！！！',
      //         type: 'warning'
      //     });
      //     return
      // }
      // if (this.multipleSelection.length > 1) {
      //     this.$message({
      //         message: '警告：请选择一条需要接单的数据！！！',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.$confirm("此操作为接单操作, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.repairOrderTake(id);
        })
        .catch(() => {});
    },
    async repairOrderTake(id) {
      var param = {
        ID: id,
      };
      const selectRes = await repairOrderTake(param);
      if (selectRes.data.result) {
        this.$message({
          message: selectRes.message,
          type: "success",
        });
        this.repairOrderMultiAuthQueryLimit();
      } else {
        this.$message.error(selectRes.message);
      }
    },

    // 执行--开始
    implementOperation(id) {
      // if (this.multipleSelection.join(',') == '') {
      //     this.$message({
      //         message: '警告：请选择需要执行的工单！！！',
      //         type: 'warning'
      //     });
      //     return
      // }
      // if (this.multipleSelection.length > 1) {
      //     this.$message({
      //         message: '警告：请选择一条需要执行的工单！！！',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.dialogVisibleImplement = true;
      this.repairOrderExecuteTypeQuery();
      this.typequery = id;
    },
    async repairOrderExecuteTypeQuery() {
      const selectRes = await repairOrderExecuteTypeQuery();
      this.executeType = selectRes.array;
    },
    async confrimImplement() {
      this.formImplement.ID = this.typequery;
      const selectRes = await repairOrderExecute(this.formImplement);
      if (selectRes.data.result) {
        this.$message({
          message: selectRes.message,
          type: "success",
        });
        this.dialogVisibleImplement = false;
        this.repairOrderMultiAuthQueryLimit();
      } else {
        this.$message.error(selectRes.message);
      }
    },

    // 暂离--开始
    temporarilyPart(id) {
      // if (this.multipleSelection.join(',') == '') {
      //     this.$message({
      //         message: '警告：请选择需要暂离的工单',
      //         type: 'warning'
      //     });
      //     return
      // }
      // if (this.multipleSelection.length > 1) {
      //     this.$message({
      //         message: '警告：请选择一条需要暂离的工单！！！',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.$confirm("此操作为暂离工单操作, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.repairOrderPause(id);
        })
        .catch(() => {});
    },
    async repairOrderPause(id) {
      var param = {
        ID: id,
      };
      const selectRes = await repairOrderPause(param);
      if (selectRes.data.result) {
        this.$message({
          message: selectRes.message,
          type: "success",
        });
        this.repairOrderMultiAuthQueryLimit();
      } else {
        this.$message.error(selectRes.message);
      }
    },

    // 暂离结束--开始
    temporarilyPartEnd(id) {
      // if (this.multipleSelection.join(',') == '') {
      //     this.$message({
      //         message: '警告：请选择需要暂离结束的工单',
      //         type: 'warning'
      //     });
      //     return
      // }
      // if (this.multipleSelection.length > 1) {
      //     this.$message({
      //         message: '警告：请选择一条需要暂离结束的工单！！！',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.$confirm("此操作为暂离结束工单操作, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.repairOrderContinue(id);
        })
        .catch(() => {});
    },
    async repairOrderContinue(id) {
      var param = {
        ID: id,
      };
      const selectRes = await repairOrderContinue(param);
      if (selectRes.data.result) {
        this.$message({
          message: selectRes.message,
          type: "success",
        });
        this.repairOrderMultiAuthQueryLimit();
      } else {
        this.$message.error(selectRes.message);
      }
    },

    // 维修工单结单--开始
    orderStatement(id) {
      // if (this.multipleSelection.join(',') == '') {
      //     this.$message({
      //         message: '警告：请选择需要结单的工单',
      //         type: 'warning'
      //     });
      //     return
      // }
      // if (this.multipleSelection.length > 1) {
      //     this.$message({
      //         message: '警告：请选择一条需要结单的工单！！！',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.formStatement = {};
      this.formStatement = {
        machineStop: false,
      };
      this.dialogVisibleStatement = true;
      this.state = id.ID;
      this.repairOrderFinishTypeQuery(id);
    },
    async repairOrderFinishTypeQuery(id) {
      const selectRes = await repairOrderFinishTypeQuery();
      // this.finishTypeQuery = selectRes.array;
      if (id.orderStatus == 7) {
        // 常规维修结单操作
        var authorityadmin = selectRes.array.filter(
          (itmer) => itmer.value == 0
        );
        console.log(authorityadmin);
        this.finishTypeQuery = authorityadmin;
      }
      if (id.orderStatus == 10) {
        //验证不通过-维修结单操作
        var authorityadmin = selectRes.array.filter(
          (itmer) => itmer.value == 1
        );
        console.log(authorityadmin);
        this.finishTypeQuery = authorityadmin;
      }
      //   this.finishTypeQuery = selectRes.array;
      //   for(let i =0;i<this.finishTypeQuery.length;i++){
      //       this.finishTypeQuery[i].disabled = true
      //     if(id.orderStatus==10){
      //       this.finishTypeQuery[1].disabled = false
      //   }
      //   if(id.orderStatus==7){
      //       this.finishTypeQuery[0].disabled = false
      //   }
      //   }
      console.log(this.finishTypeQuery);
    },
    async confirmStatement() {
      var param = {
        ID: this.state,
        orderFinishType: this.formStatement.orderFinishType,
        machineStop: this.formStatement.machineStop,
        execDesc: this.formStatement.execDesc,
        spotCondition: this.formStatement.spotCondition,
        execFee: this.formStatement.execFee,
        remarks: this.formStatement.remarks,
        stopLen: this.formStatement.stopLen,
      };
      // console.log("-----------",param)
      // if(!param.orderFinishType || param.orderFinishType=="" ){
      //     this.$message.error("请填写结单类型");
      //     return
      // }else if(!param.machineStop || param.machineStop=="" ){
      //     this.$message.error("请选择勾选是否停机！");
      //     return
      // }else if(!param.execDesc || param.execDesc=="" ){
      //     this.$message.error("请填写工作描述");
      //     return
      // }else if(!param.spotCondition || param.spotCondition==""){
      //      this.$message.error("请填写现场情况");
      //     return
      // }else if(!param.execFee || param.execFee==""){
      //     this.$message.error("请填写维修费用");
      //     return
      // }else if(!param.stopLen || param.stopLen==""){
      //      this.$message.error("请勾选停机时长");
      //     return
      // }
      // this.formStatement.ID = this.state
      if (this.formStatement.machineStop) {
        param.stopLen = this.formStatement.stopLen;
      }

      const selectRes = await repairOrderRepairFinish(param);
      if (selectRes.data.result) {
        this.$message({
          message: selectRes.message,
          type: "success",
        });
        this.dialogVisibleStatement = false;
        this.formStatement = {};
        this.repairOrderMultiAuthQueryLimit();
      } else {
        this.$message.error("请填写必填项！");
      }
    },
    //     confirmStatement(formName){
    //          this.$refs[formName].validate((valid) => {
    //   if (valid) {
    //     alert('submit!');
    //   } else {
    //     console.log('error submit!!');
    //     return false;
    //   }
    // });
    //     },

    // 验证
    verificationOperation(id) {
      // console.log(this.formStatement);
      // if (this.multipleSelection.join(',') == '') {
      //     this.$message({
      //         message: '警告：请选择需要验证的工单',
      //         type: 'warning'
      //     });
      //     return
      // }
      // if (this.multipleSelection.length > 1) {
      //     this.$message({
      //         message: '警告：请选择一条需要验证的工单！！！',
      //         type: 'warning'
      //     });
      //     return
      // }  
      this.formStatement = {};
      this.formStatement = {
        verifyResult: false,
      };
      this.formStatement.ID = id;
      this.dialogVisibleVerification = true;
    },
    //验证
    async confirmVerification() {
      var param = {
        ID: this.formStatement.ID,
        verifyResult: this.formStatement.verifyResult,
        verifyRemarks: this.formStatement.verifyRemarks,
      };
      this.dialogVisibleVerification = false;
      //this.formStatement.ID = this.verification;
      //const selectRes = await repairOrderRepairFinish(param);
      //const selectRes = await repairOrderVerify(this.formStatement);
      const selectRes = await repairOrderVerify(param);
      if (selectRes.data.result) {
        this.$message({
          message: selectRes.message,
          type: "success",
        });
        this.dialogVisibleVerification = false;
        this.formStatement = {};
        this.repairOrderMultiAuthQueryLimit();
      } else {
        this.$message.error(selectRes.message);
        this.dialogVisibleVerification = false;
      }
    },
    //关闭验证弹框
    closeConfirmVerification() {
      this.dialogVisibleVerification = false;
    },
    // 是否为经验性工单
    // empiricalOrder() {
    //     if (this.multipleSelection.join(',') == '') {
    //         this.$message({
    //             message: '警告：请选择需要设置经验性的工单',
    //             type: 'warning'
    //         });
    //         return
    //     }
    //     if (this.multipleSelection.length > 1) {
    //         this.$message({
    //             message: '警告：请选择一条需要设置经验性的工单！！！',
    //             type: 'warning'
    //         });
    //         return
    //     }
    //     this.$confirm('此操作为设置经验性工单操作, 是否继续?', '提示', {
    //         confirmButtonText: '确定',
    //         cancelButtonText: '取消',
    //         type: 'warning'
    //     }).then(() => {
    //         this.repairOrderMarkEmpirical();
    //     }).catch(() => {
    //         // this.dialogVisibleVerification = false
    //     });
    // },
    async repairOrderMarkEmpirical(id) {
      var param = {
        ID: id,
      };
      const selectRes = await repairOrderMarkEmpirical(param);
      if (selectRes.data.result) {
        this.$message({
          message: selectRes.message,
          type: "success",
        });
        this.repairOrderMultiAuthQueryLimit();
      } else {
        this.$message.error(selectRes.message);
      }
    },

    // 评分
    scoreOperation(id) {
      // if (this.multipleSelection.join(',') == '') {
      //     this.$message({
      //         message: '警告：请选择需要评分的工单',
      //         type: 'warning'
      //     });
      //     return
      // }
      // if (this.multipleSelection.length > 1) {
      //     this.$message({
      //         message: '警告：请选择一条需要评分的工单！！！',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.score = id;
      this.formStatement = {};
      this.dialogVisibleScore = true;
    },
    async confirmScore() {
      if (this.formStatement.execGrade) {
        this.formStatement.ID = this.score;
        const selectRes = await repairOrderRepairGrade(this.formStatement);
        if (selectRes.data.result) {
          this.$message({
            message: selectRes.message,
            type: "success",
          });
          this.dialogVisibleScore = false;
          this.formStatement = {};
          this.repairOrderMultiAuthQueryLimit();
        } else {
          this.$message.error(selectRes.message);
        }
      } else {
        this.$message.error("请选择评分等级");
      }
    },
    // 派单是否委外
    changeIsOutEntrust() {
      console.log(this.formDispatch.isOutEntrust);
    },
    // 获取单元格数据
    formatDate(row, column) {
      let data = row[column.property];
      if (data == undefined) {
        return "";
      }
      return this.tools.formatDate(data, "yyyy-MM-dd hh:mm:ss");
    },
    //导出数据
    exportRepairRecord() {
      this.$exportExcel.exportDataTwo("repairPlanTable", "维修工单表");
    },
    //详细
    dblclickGotoFaultDetails(row, column, event) {
      localStorage.setItem("oderId", row.ID);
      // this.$router.push({
      //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
      //     // query: {
      //     //     id: id
      //     // },
      //     // query:{setid:111222}
      //     // params:{setid:111222}//地址栏中看不到参数的内容
      // })
    },
    //选中的行
    handleSelectionChange(val) {
      this.multipleSelection = val;
      //要批量删除的数组
      // this.deleteNum = []; //数组赋值前，需要清空一下数组
      this.deleteNum = "";
      // this.isAuth = '';
      for (var i = 0; i < val.length; i++) {
        this.deleteNum += val[i].ID + ",";
        // this.isAuth += val[i].userID + ",";
      }
      //去掉最后一个逗号
      if (this.deleteNum.length > 0) {
        this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
      }

      var delNum = this.deleteNum;
      window.localStorage.setItem("delNum", this.deleteNum);
      // var isAuth =this.isAuth;
      // window.localStorage.setItem('isAuth')
    },
  },
};
</script>

<style scoped>
.height_94 {
  height: 94%;
}
</style>
<style>
/* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

/* .el-button:focus,
    .el-button:hover {
    } */
</style>